.eventswiper {
  height: 50vh;
  margin-bottom: 1rem;
}
.eventslide {
  background-color: #007af0;
  border-radius: 15px;
}

.shwloder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50vh;
  /* background-color: red; */
}
@media screen and (max-width: 845px) {
  .shwloder {
    height: 30vh;
  }
}
