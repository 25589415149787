.main {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  border-radius: 15px;
  box-sizing: border-box;
  overflow: hidden;
}
.main img {
  background-color: pink;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 15px;
  filter: blur(2px);
  opacity: 0.5;
  transition: all 0.5s;
}

.main:hover img {
  transform: scale(1.3);
  filter: blur(0.5px);
}
.cont {
  position: absolute;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 85%;
}

.cont1 h1 {
  font-size: 3.5rem;
  font-weight: 900;
  font-family: "Poppins", sans-serif;
  letter-spacing: 3px;
  color: white;
}

.cont2 h2 {
  font-size: 2.5rem;
  font-weight: lighter;
  color: white;
}

.cont3 p {
  padding-top: 2rem;
}
@media screen and (max-width: 845px) {
  .cont1 h1 {
    font-size: 3rem;
  }

  .cont2 h2 {
    font-size: 2rem;
  }
}
