.ctre {
  /* background-color: red; */
  display: grid;
  grid-template-columns: 10rem 1fr 10rem;
  box-sizing: border-box;
  /* padding-top: 4rem; */
  width: 100%;
}
.ctre2 {
  /* position: relative; */
  grid-column: 2/3;
  /* background-color: burlywood; */
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
.ctre3 {
  position: relative;
  width: 300px;
  /* background-color: blue; */
  border-radius: 15px;
}
.dtt {
  border-radius: 15px;
  z-index: 1;
  position: absolute;
  width: 300px;
  height: 100%;
  /* opacity: 0.5; */
  /* background-color: #007af0; */
  display: flex;
  align-items: center;
  flex-direction: column;
}
.ctre3 img {
  position: relative;
  width: 300px;
}

.bttn {
  padding: 1rem;
}
.bttnn {
  padding: 0.5rem 1rem 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  outline: none;
  border: none;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-color: #007af0;
  cursor: pointer;
}
.lgo{
  padding-top: 3.7rem;
  /* background-color: rebeccapurple; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.lgo img{
  height: auto;
  width: 150px;
}
.evv{
  padding-top: 1.5rem;
  /* background-color: red; */
  width: 13rem;
  height: 14rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.spnn{
  font-style: italic;
}
.orgnn{
  width: 13rem;
  height: 5rem;
  /* background-color: rgb(78, 74, 74); */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.ptpt
{
  padding-top: 4.5rem;
  /* background-color: rgb(167, 223, 12); */
  width: 13rem;
  /* height: 5rem; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

}
/* media queries */

@media screen and (max-width: 1275px) {
  .ctre {
    grid-template-columns: 3rem 1fr 3rem;
  }
}

@media screen and (max-width: 1020px) {
  .ctre {
    grid-template-columns: 1rem 1fr 1rem;
    padding-top: 1rem;
  }
}

@media screen and (max-width: 845px) {
  .ctre3 {
    width: 300px;
  }
}

@media screen and (max-width: 600px) {
}
