@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap");

.container {
  /* background-color: rgb(129, 99, 158); */
  display: grid;
  grid-template-columns: 10rem 1fr 1fr 1fr 10rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.grid {
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
  height: 20rem;
  margin: 1rem;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}
.c-1 {
  grid-column: 2/3;
  background-color: rgb(63, 42, 180);
}

.c-2 {
  grid-column: 3/4;
  background-color: rgb(112, 85, 44);
}

.c-3 {
  grid-column: 4/5;
  background-color: pink;
}

.c-4 {
  grid-column: -5/-4;
  background-color: rgb(99, 32, 43);
}
.c-5 {
  grid-column: -4/-3;
  background-color: rgb(16, 156, 114);
}

.c-6 {
  grid-column: -3/-2;
  background-color: rgb(68, 58, 60);
}

/* .c-1 img {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  filter: blur(2px);
  opacity: 0.5;

} */
/* ------------------cards-------------------------- */
/*
.container .c-1 .c-1-content {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.container .c-1 .c-1-content .con-main h1 {
  font-size: 3.5rem;
  font-weight: 900;
  font-family: "Poppins", sans-serif;
  letter-spacing: 3px;
  color: white;
}

.container .c-1 .c-1-content .con-main h2 {
  font-size: 2.5rem;
  font-weight: lighter;
  color: white;
}

.con-main-p {
  padding-top: 2rem;
  width: 50%;
  color: white;
}

.c-1 img {
  width: 0;
  height: 0;
  min-height: 100%;
  min-width: 100%;
  object-fit: cover;
  border-radius: 15px;
  position: relative;
  opacity: 0.5;
  filter: blur(1px);
} */

/* media queries */

@media screen and (max-width: 1500px) {
}

@media screen and (max-width: 1275px) {
  .container {
    grid-template-columns: 2rem 1fr 1fr 1fr 2rem;
  }
  .c-1 {
    grid-column: 2/3;
  }
  .c-2 {
    grid-column: 3/4;
  }

  .c-3 {
    grid-column: 4/5;
  }

  .c-4 {
    grid-column: -5/-4;
  }
  .c-5 {
    grid-column: -4/-3;
  }

  .c-6 {
    grid-column: -3/-2;
  }
}

@media screen and (max-width: 1020px) {
  .container {
    grid-template-columns: 3rem 1fr 1fr 3rem;
  }
  .c-1 {
    grid-column: 2/3;
  }
  .c-2 {
    grid-column: -3/-2;
  }

  .c-3 {
    grid-column: -4/-3;
  }

  .c-4 {
    grid-column: -3/-2;
  }
  .c-5 {
    grid-column: -4/-3;
  }

  .c-6 {
    grid-column: -3/-2;
  }
}

@media screen and (max-width: 845px) {
  .container {
    grid-template-columns: 1rem 1fr 1rem;
  }
  .c-1 {
    grid-column: 2/3;
  }
  .c-2 {
    grid-column: -3/-2;
  }

  .c-3 {
    grid-column: -3/-2;
  }

  .c-4 {
    grid-column: -3/-2;
  }
  .c-5 {
    grid-column: -3/-2;
  }

  .c-6 {
    grid-column: -3/-2;
  }
}
