.mainnav {
  height: 7.5rem;
  background-color: #007af0;
  display: grid;
  grid-template-columns: 10rem 1fr 2fr 1.2fr 0.3fr 10rem;
}
.dash-logo {
  cursor: pointer;
}
.btnlogout {
  grid-column: 4/5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mainnavcontainer {
  /* background-color: pink; */
  grid-column: 4/5;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.mainnavcont {
  /* background-color: red; */

  display: flex;
  align-items: center;
  justify-content: center;
}
.downicn {
  font-size: 1.5rem;
  color: white;
  /* background-color: #007af0; */
  cursor: pointer;
  padding: 0;
}
.logoutbtn {
  grid-column: 5/6;
  display: flex;
  align-items: center;
  justify-content: center;
}
.downmenu {
  display: none;
  background-image: linear-gradient(
  43deg,
  #034b94 0%,
  #469bf0 50%,
  #e4cb9c 100%
);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5.5px);
-webkit-backdrop-filter: blur(5.5px);
  z-index: 2;
  width: 12rem;
  height: auto;
  /* background-color: red; */
  position: absolute;
  margin-top: 19rem;
  border-radius: 15px;
}
.menulist {
  padding: 1rem 0.5rem 1rem 0.5rem;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.menulist li{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  background-color: white;
  border-radius: 5px;
  margin: 0.5rem;
  padding: 0.5rem;
}
.showuser {
  padding-left: 0.5rem;
  color: white;
  font-weight: lighter;
  /* display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; */
}

.profileicn {
  color: white;
  font-size: 3.5rem;
  /* background-color: rebeccapurple; */
  /* margin: 0.5rem; */
}
.logouticon {
  cursor: pointer;
  font-size: 2.5rem;
  color: white;
  font-weight: lighter;
}

/* media queries */

@media screen and (max-width: 1275px) {
  .mainnav {
    grid-template-columns: 3rem 1fr 2fr 1.2fr 0.3fr 3rem;
  }
}

@media screen and (max-width: 1020px) {
  .mainnav {
    grid-template-columns: 1rem 1fr 2fr 1.2fr 0.3fr 1rem;
  }
}

@media screen and (max-width: 845px) {
  .mainnav {
    height: 6rem;
  }
}

@media screen and (max-width: 600px) {
  .mainnav {
    grid-template-columns: 1rem 1fr 2fr 0.5fr 0.3fr 1rem;
  }
  .profileicn {
    /* padding: 1rem; */
    font-size: 2rem;
  }
  .mainnavcont {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .showuser {
    padding: 0;
    padding-top: 0.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 0.6rem;
  }
  .logouticon {
    font-size: 1.5rem;
  }
  .downicn {
    font-size: 1rem;
  }
  .downmenu {
    width: 10rem;
    margin-top: 20rem;

  }
}
