
.slidedata {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

}
.subslidedata {
  width: 100%;
}
.slidedatacont1 {
  height: 50%;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slidedata img {
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.overlay {
  position: absolute;
  width: 92%;
  height: 92%;
  border-radius: 15px;
  background: linear-gradient(45deg, #007af0, rgb(189, 28, 28));
  opacity: 0.7;
}
.evdetails {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.evdetails2 {
  background: rgba(0, 0, 0, 0.65);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  /* height: 25%; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-left: 1rem;
  padding-right: 1rem;
}

.evdetails2 h2 {
  font-size: 2rem;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
  color: white;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}
.evdetails2 h3 {
  font-size: 1rem;
  font-weight: lighter;
  font-family: "Poppins", sans-serif;
  color: white;
  padding-bottom: 1.5rem;
}

.venuedate {
  display: flex;
  flex-direction: column;
}
.date,
.venue {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 0.5rem;
}
.date p {
  padding-left: 0.5rem;
  font-size: 1rem;
  color: white;
}

.venue p {
  padding-left: 0.5rem;
  font-size: 1rem;
  color: white;
}
.icns {
  color: white;
  font-size: 1.1rem;
}

.tktbtn{
  padding :0.75rem;
}
.tktbtnn{
  color: white;
  background-color: #007af0;
  font-size: 1.2rem;
  border-radius: 15px;
  border: none;
  outline: no;
  padding :0.5rem 1rem 0.5rem 1rem;
}
/* media queries */

@media screen and (max-width: 1500px) {
}

@media screen and (max-width: 1275px) {
}

@media screen and (max-width: 1020px) {
  .eventswiper {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

@media screen and (max-width: 845px) {
  .evdetails2 h2 {
    font-size: 1.8rem;
  }
  .evdetails2 h3 {
    font-size: 0.9rem;
  }
  .icns {
    font-size: 1rem;
  }
  .venue p {
    font-size: 0.9rem;
  }
  .date p {
    font-size: 0.9rem;
  }
  .tktbtnn{
    color: white;
    background-color: #007af0;
    font-size: 1rem;
    border-radius: 15px;
    border: none;
    outline: no;
    padding :0.3rem 1rem 0.3rem 1rem;
  }
}
