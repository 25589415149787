.loadercontainer {
  position: absolute;
  /* background: rgba(130, 150, 206, 0.75); */
  /* background-color:rgba(red, green, blue, alpha); */

  /* opacity: 0.5; */
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.loaderlottie {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: blue; */

  width: 100%;
  height: 100%;
}
.ldrlottie{
width: 50%;
height: 50%;
/* opacity: 1; */
}