/* @import 'bootstrap/dist/css/bootstrap.min.css'; */
.bootstrap-scope {
  @import "bootstrap/dist/css/bootstrap.min.css";
}

.eventcontainer {
  width: 100%;
  /* height: 150vh; */
  /* background-color: red; */
  display: grid;
  grid-template-columns: 10rem 1fr 10rem;
  /* place-items: center; */
}
.cardswiper {
  /* height: 50%; */
  width: 100%;
  grid-column: 2/3;
  /* background-color: pink; */
  padding: 2rem;
}
.eventead {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0.5rem;
  /* padding-bottom: 1rem; */
}
.titlehead h1 {
  font-family: "Poppins", sans-serif;
  font-size: 2rem;
}
.mySwipercard {
  width: 25rem;
  height: 27rem;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  /* font-size: 22px; */
  /* font-weight: bold; */
  /* color: #fff; */
}

.sldimg img {
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.sldimg:nth-child(1n) {
  background-color: rgb(206, 17, 17);
}

.sldimg:nth-child(2n) {
  background-color: rgb(0, 140, 255);
}
/*
.swiper-slide:nth-child(3n) {
  background-color: rgb(10, 184, 111);
}

.swiper-slide:nth-child(4n) {
  background-color: rgb(211, 122, 7);
}

.swiper-slide:nth-child(5n) {
  background-color: rgb(118, 163, 12);
}

.swiper-slide:nth-child(6n) {
  background-color: rgb(180, 10, 47);
}

.swiper-slide:nth-child(7n) {
  background-color: rgb(35, 99, 19);
}

.swiper-slide:nth-child(8n) {
  background-color: rgb(0, 68, 255);
}

.swiper-slide:nth-child(9n) {
  background-color: rgb(218, 12, 218);
}

.swiper-slide:nth-child(10n) {
  background-color: rgb(54, 94, 77);
} */

.dtamain {
  position: relative;
  /* background-color: red; */
  width: 100%;
  /* height: 50vh; */
  display: grid;
  grid-template-columns: 20rem 1fr 20rem;
}
.dtamain1 {
  /* background-color: pink; */
  position: relative;
  width: 100%;
  grid-column: 2/3;
}
.dtamain2 {
  padding: 2rem;

  background-image: linear-gradient(
    45deg,
    #034b94 0%,
    #469bf0 50%,
    #e4cb9c 100%
  );
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5.5px);
  -webkit-backdrop-filter: blur(5.5px);
  border-radius: 15px;
}
.spn {
  font-family: "Poppins", sans-serif;
  font-weight: lighter;
  font-style: italic;
}
.dtamain2 h3 {
  font-family: "Poppins", sans-serif;
  font-size: 2.2rem;
  /* padding-bottom: 1rem; */
  /* font-weight: 100; */
  /* padding: 1rem; */
  /* letter-spacing: -1px; */
  /* font-style: italic; */
}
.hosteddate h3 {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  /* font-weight: lighter; */
  /* font-style: italic; */
  padding-bottom: 1rem;
}
.sptn {
  padding-bottom: 1rem;
}
.sptn h3 {
  padding-bottom: 0rem;
}

.orgn {
  padding-top: 1rem;
}
.atndbtn {
  outline: none;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: bold;
  color: black;
  background-color: white;
  margin-top: 1rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  font-size: 1.2rem;
}

.modal {
  /* display: none; */

  position: absolute;
  /* background-color: blue; */
  grid-column: 2/3;
  width: 100%;
  display: none;
  /* align-items: center; */
  justify-content: center;
  height: 100%;
  /* opacity: 0.5; */
  border-radius: 15px;
}
.modal-content {
  /* z-index: 1000; */
  margin-top: 1rem;
  background-color: pink;
  /* padding: 0.5rem 1rem 1rem 1rem; */
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  width: 500px;
  /* height: 100px; */
  border-radius: 5px;
}
.modal-header {
  background-color: rgb(224, 72, 72);
  padding: 1rem 1.5rem 1rem 0.5rem;
  /* width: 100%; */
  /* border-radius: 5px; */
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.modal-header h5 {
  font-family: "Poppins", sans-serif;
  font-size: 1.5rem;
}
.modal-body {
  /* padding: 2rem; */
  font-size: 1rem;
  margin: 0.5rem 0 0.5rem 0;
  font-family: "Poppins", sans-serif;
  padding: 0.5rem 1.5rem 0.5rem 0.5rem;
}
.modal-footer {
  background-color: rgb(172, 159, 159);
  padding: 1rem 1.5rem 1rem 0.5rem;
}
.dbtn {
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  padding: 0.5rem 0.8rem 0.5rem 0.8rem;
  outline: none;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 500;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.btn-primary {
  margin-left: 0.5rem;

  /* color: white; */
}
.btn-secondary{
  background-color: #9bbcdd;
}
/* media queries */
@media screen and (max-width: 1500px) {
  .dtamain {
    grid-template-columns: 15rem 1fr 15rem;
  }
}
@media screen and (max-width: 1275px) {
  .eventcontainer {
    grid-template-columns: 3rem 1fr 3rem;
  }
  .dtamain {
    grid-template-columns: 10rem 1fr 10rem;
  }
}
@media screen and (max-width: 1020px) {
  .eventcontainer {
    grid-template-columns: 1rem 1fr 1rem;
  }
  .mySwipercard {
    width: 22rem;
    height: 25rem;
  }
  .dtamain {
    grid-template-columns: 5rem 1fr 5rem;
  }
  .dtamain2 h3 {
    font-size: 1.5rem;
    padding-bottom: 0.3rem;
    /* padding-bottom: 0.3rem; */
  }
  .hosteddate h3 {
    font-family: "Poppins", sans-serif;
    font-size: 0.9rem;
    /* font-weight: lighter; */
    /* font-style: italic; */
    padding-bottom: 0.3rem;
  }
  .dtamain2 {
    padding: 1rem;
    /* margin: 2rem; */
    /* background-color: blue;
    border-radius: 15px; */
  }
  .titlehead h1 {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 845px) {
  .mySwipercard {
    width: 16rem;
    height: 20rem;
  }
  .dtamain {
    grid-template-columns: 1rem 1fr 1rem;
  }
  .modal-content {
    width: 300px;
  }
}
