

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap");
.heading {
  /* font-family: "Poppins", sans-serif; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 2rem;
  padding-bottom: 2rem;
  /* background-color: #007af0; */
}
.heading-d1 h3 {
  font-weight: lighter;
  /* color: white; */
}
.heading .heading-d h1 {
  font-family: "Poppins", sans-serif;
  /* color: white; */
}

.swiper {
  width: 80%;
  height: 70vh;
  /* z-index: -1; */
}
.mySwiper{
  z-index: 0;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  /* background: yellow; */

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  /* position: absolute; */
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}


/* media queries */

@media screen and (max-width: 1500px) {

}

@media screen and (max-width: 1275px) {
  .swiper {
    width: 90%;
  }
}

@media screen and (max-width: 1020px) {
  .swiper {
    width: 100%;
  }
}

@media screen and (max-width: 845px) {
  .swiper {
    width: 100%;
    /* z-index: -1; */
    /* height: 50vh; */
  }
}