.fixed-nav-details {
  background: rgba(255, 255, 255, 0);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(6.6px);
  -webkit-backdrop-filter: blur(6.6px);
  height: 7.5rem;
  width: 100%;
  display: grid;
  grid-template-columns: 10rem 1fr 2fr 1.5fr 10rem;
  /* position: fixed; */
}

.fixed-nav-details-clicked {
  background: rgba(255, 255, 255, 0);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(6.6px);
  -webkit-backdrop-filter: blur(6.6px);
  height: 6rem;
  width: 100%;
  display: grid;
  grid-template-columns: 1rem 1fr 3fr 0fr 1.5fr 1rem;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: 2/3;
}

.logo img {
  margin: 0px;
  padding: 0px;
  width: 15rem;
  height: auto;
  cursor: pointer;
}

.list {
  grid-column: 3/4;
}

.list ul {
  height: 7.5rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.list li a {
  font-size: 1.3rem;
  color: black;
}

.list a:hover {
  color: #007af0;
}
.btns {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: 4/5;
}

.btns .btn-signup {
  background-color: #007af0;
  color: white;
}
.btns .btn-login {
  background: white;
}

.hamburger {
  display: none;
  grid-column: 5/6;
}

.mobile-menu {
  position: absolute;
  z-index: 1;
  width: 100vw;
  background: rgba(255, 255, 255, 0);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  padding-top: 2rem;
  padding-bottom: 2rem;

  display: none;
  align-items: center;
  justify-content: center;
}

.mobile-menu-content ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mobile-menu-content ul li {
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  font-size: 1.3rem;
}

.mobile-btn {
  display: flex;
  flex-direction: column;
}

.btnn {
  margin: 0.5rem;
  padding: 0.8rem 1.5rem 0.8rem 1.5rem;
  font-size: 1.3rem;
  border: 1px solid #007af0;
  border-radius: 8px;
  cursor: pointer;
}
.m-btn-login {
  background: transparent;
}
.m-btn-signup {
  background: #007af0;
  color: white;
}

/* media queries */

@media screen and (max-width: 1275px) {
  .fixed-nav-details {
    grid-template-columns: 3rem 1fr 2fr 1.5fr 3rem;
  }
}

@media screen and (max-width: 1020px) {
  .fixed-nav-details {
    grid-template-columns: 1rem 1fr 2fr 1.5fr 1rem;
  }
  .logo img {
    width: 12rem;
  }
}

@media screen and (max-width: 845px) {
  .fixed-nav-details {
    grid-template-columns: 1rem 1fr 3fr 0fr 1.5fr 1rem;
    height: 6rem;
  }

  .list,
  .btns {
    display: none;
  }
  .hamburger {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 4rem;
  }
  .logo img {
    width: 10rem;
  }
}
