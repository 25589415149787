.allmcontainer {
  /* background-color: pink; */
  /* height: calc(100vh - 15.9rem); */
  height: auto;
  display: grid;
  grid-template-columns: 10rem 1fr 10rem;
}
.allmcontainer2 {
  grid-column: 2/3;
  width: 100%;
  /* heig
  ht: 100%; */
}

.allnodata {
  /* background-color: red; */
  /* height: calc(100vh - 15.9rem);; */
  width: 100%;
  display: grid;
  place-items: center;
}
.allnodatalottie {
  margin-top: 8rem;
  width: 25rem;
}
.alleventlistcontainer {
  /* background-color: rgb(212, 34, 72); */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr));
  grid-gap: 1rem;
  width: 100%;
  /* height: 100%; */
  height: min-content;
}
.alllistitems {
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.5);
  border-radius: 10px;

  /* height: 15rem; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem;
}
.allfeatureimg1 {
  position: relative;
  display: flex;
  justify-content: center;
  /* flex-direction: column; */
  height: 15rem;
  width: 100%;
  /* padding: 0.5rem 0 0.5rem 0; */
  /* background-color: pink; */
}
.allfeatureimg {
  position: relative;
  background-color: rgb(219, 222, 224);
  /* margin: 0.5rem 0 0.5rem 0; */
  border-radius: 10px;
  /* height: 100%; */
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.allfeatureimg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  opacity: 0.5;
}
.allttle {
  /* margin: 0.5rem 0 0.5rem 0; */
  position: absolute;
  /* background-color: oldlace; */
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  /* display: grid; */
  /* place-items: center; */
}
.ttledata
{
  /* background-color: red; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
margin-bottom: 2rem;

}
.ttledata h3{
  font-size: 1.2rem;
  font-weight: lighter;
}
.allttle h1 {
  font-size: 2rem;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
.alldet {
  height: 20rem;
  padding: 1rem;
  width: 100%;
  background-color: rgb(213, 209, 219);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;
}
.alldet span {
  font-weight: 600;
}
.det p {
  font-family: "Poppins", sans-serif;
  /* font-size: 1.1rem; */
  padding: 0.1rem;
  /* background-color: aliceblue; */
}
.allebtn {
  width: fit-content;
  padding: 0.4rem 0.75rem 0.4rem 0.75rem;
  margin: 0.2rem;
  border-radius: 5px;
  border: none;
  outline: none;
  font-size: 1rem;
  background-color: #007af0;
  color: white;
  cursor: pointer;
  box-shadow: 0 4px 30px rgba(255, 255, 255, 0.4);
}
/* media queries */

@media screen and (max-width: 1275px) {
  .allmcontainer {
    grid-template-columns: 3rem 1fr 3rem;
  }
}

@media screen and (max-width: 1020px) {
  .allmcontainer {
    grid-template-columns: 1rem 1fr 1rem;
  }
}

@media screen and (max-width: 845px) {
  .alleventlistcontainer {
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  }
  .alllistitems {
    height: auto;
  }
  .allnodatalottie {
    width: 15rem;
  }
  .allttle h1 {
    font-size: 1.5rem;
  }
  .alldet p {
    font-size: 0.8rem;
  }
  .allfeatureimg1 {
    height: 12rem;

  }
  .allebtn {
    font-size: 0.8rem;
  }
  .alldet {
    height: 18rem;
  }

}

@media screen and (max-width: 600px) {
  /* .eventlistcontainer {
    grid-template-columns: repeat(auto-fill , minmax(15rem , 1fr) );
  }
  .listitems {
    height: 20rem;
  } */
}
