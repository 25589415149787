.main {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-w {
  width: 50%;
  height: auto;
}


@media screen and (max-width: 845px) {
  .error-w {
    width: 80%;
    height: auto;
  }
}